// 表单验证
const validatePic = (rule, value, callback) => {
  if (!value || JSON.stringify(value) === '{}') {
    return callback(new Error('请上传证照'))
  }
  return callback()
}

export default {
  title: {
    required: true,
    message: '请输入标题',
    trigger: 'blur'
  },
  type: {
    required: true,
    message: '请选择类型',
    trigger: 'change'
  },
  content: [
    {
      required: true,
      message: '请输入内容',
      trigger: 'blur'
    }
  ],
  fileObj: [
    { required: true, message: '请上传图片', trigger: 'blur' },
    { validator: validatePic, trigger: 'blur' }
  ],
  group: {
    required: true,
    message: '请选择分组',
    trigger: 'change'
  }
  // keywords: [{ required: true, message: '请选择关键词' }]
}
