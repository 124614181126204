<template>
  <div class="pagoda-add-layout-v1">
    <layout-header
      content-title="编辑话术库"
      @backToList="handleBackToList"
    ></layout-header>
    <div class="pagoda-add-layout-v1__content">
      <div class="pagoda-add-layout-v1__title"></div>
      <div class="pagoda-add-layout-v1__main">
        <el-form
          class="el-form-reset-block"
          :rules="rules"
          :model="formData"
          ref="formRef"
        >
          <el-row class="pagoda-form-grid">
            <el-col v-bind="$pagoda.form.grid">
              <el-form-item label="标题" prop="title">
                <el-input
                  v-model="formData.title"
                  placeholder="请输入话术标题"
                  maxlength="30"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col v-bind="$pagoda.form.grid">
              <el-form-item label="选择分组" prop="group_id">
                <group-select
                  type="speechcraft"
                  v-model="formData.group_id"
                  @change="handleGroupId"
                  ref="group"
                ></group-select>
              </el-form-item>
            </el-col>
            <el-col v-bind="$pagoda.form.grid">
              <el-form-item label="类型" prop="type_id">
                <el-select v-model="formData.type_id" placeholder="请选择类型">
                  <el-option
                    v-for="item in typeList"
                    :key="item.id"
                    :label="item.category_name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="pagoda-form-grid">
            <el-col :span="24">
              <el-form-item label="内容" prop="content">
                <template>
                  <el-input
                    id="editContent"
                    type="textarea"
                    v-model="formData.content"
                    placeholder="请输入内容"
                    maxlength="800"
                    :autosize="{ minRows: 3 }"
                  ></el-input>
                  <el-tabs v-model="emojiTab">
                    <el-tab-pane
                      v-for="(item, index) in emojiList"
                      :key="index"
                      :label="item.name"
                      :name="item.label"
                    >
                      <div class="emoji-box">
                        <div
                          v-for="(subItem, subIndex) in item.value"
                          :key="subIndex"
                          @click="insertText(subItem)"
                        >
                          <span>{{ subItem }}</span>
                        </div>
                      </div>
                    </el-tab-pane>
                  </el-tabs>
                </template>
              </el-form-item>
            </el-col>
          </el-row>
          <!--关键词列表  -->
          <el-row>
            <el-form-item label="关键词设置" prop="keywords">
              <el-select
                @remove-tag="removetag"
                v-model="formData.keywords"
                multiple
                filterable
                allow-create
                default-first-option
                placeholder="请选择关键词"
              >
                <el-option
                  v-for="(item, index) in keywordList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                  <div class="keyword-info">
                    <span>{{ item.name }}</span>
                    <el-popover
                      placement="bottom"
                      width="160"
                      v-model="item.visible"
                    >
                      <!-- trigger="hover" -->
                      <div style="text-align: right; margin: 0">
                        <el-input
                          v-model="currentKeyInfo.name"
                          placeholder="请输入内容"
                          autofocus
                          @keyup.enter.native="onConfirm($event, index)"
                        ></el-input>
                        <el-button
                          size="mini"
                          type="text"
                          @click.stop="deleteKeyword($event, index)"
                        >
                          删除
                        </el-button>
                        <el-button
                          size="mini"
                          type="text"
                          @click.stop="item.visible = false"
                        >
                          取消
                        </el-button>
                      </div>
                      <i
                        class="el-icon-more"
                        slot="reference"
                        @click.stop="showModal(index)"
                      ></i>
                    </el-popover>
                  </div>
                </el-option>
              </el-select>
            </el-form-item>
          </el-row>
        </el-form>
      </div>
    </div>
    <div class="pagoda-add-layout-v1__footer">
      <div class="pagoda-button-group">
        <el-button @click="handleCancel">取消</el-button>
        <el-button type="primary" v-debounce @click="handleSubmit">
          保存
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { emojiList } from '@/utils/emoji'
import { getPositionForTextArea } from '@/utils/common'
import groupSelect from '@/components/group-select'
import http from '@/services/api/reach'
import rules from './rules'
export default {
  data() {
    return {
      formData: {
        title: '',
        group_id: '',
        id: '',
        content: '',
        keywords: []
      },
      emojiList: emojiList,
      emojiTab: 'face',
      rules: rules,
      // typeList: this.$store.getters.materialTypeList || [],
      toolbar: {},
      currentIndex: null,
      currentKeyInfo: { name: '' },
      contentKeywordList: [], // 详情中的关键词列表
      keywordList: [], // 关键词列表;
      visible: false,
      showUplodImg: true,
      type: 1, // type为1是添加素材/话术，2是编辑
      timer: null // 定时器
    }
  },
  computed: {
    typeList() {
      return this.$store.getters.materialTypeList || []
    }
  },
  watch: {
    typeList: {
      handler(val) {
        // 39
        this.formData.type_id = val[0].id
        console.log('watch---typeList', val)
      },
      deep: true
    }
  },
  components: {
    groupSelect
  },
  created() {
    this.queryObj = this.$route.query
    this.formData.id = this.queryObj.id
    this.type = this.queryObj.type
    this.getKeywordList()
    this.getMaterialType()
    if (this.type === '2') {
      this.getMaterialDetail(this.formData.id)
    }
  },
  methods: {
    getKeywordList() {
      http
        .getKeyWordList()
        .then((res) => {
          this.keywordList = res.data.list
        })
        .catch(() => {})
    },
    changeVisiable(index) {
      this.$set(this.keywordList[index], 'visible', false)
    },
    async onConfirm(event, index) {
      let name = this.currentKeyInfo.name
      if (name) {
        await this.editKeyWord()
        this.changeVisiable(index)
        // this.$message.success('编辑成功')
      } else {
        this.$message.error('请输入关键词')
      }
    },
    getMaterialType() {
      const params = { type: 'speechcraft' }
      http
        .getMaterialType(params)
        .then((res) => {
          // 目前只需要公司话术这一个类型
          const list = res.data.slice(1, 2)
          this.$store.commit('MATERIAL_TYPE', list)
        })
        .catch((error) => {
          this.$message.error(error)
        })
    },
    // 删除关键字和话术的关联关系;
    removetag(id) {
      console.log('event---event', id)
      if (typeof id === 'string') {
      } else {
        let contentKeywordList = this.contentKeywordList
        let contentKeywordId = (
          contentKeywordList.find((ele) => {
            return ele.id === id
          }) || {}
        ).content_keyword_id
        if (contentKeywordId) {
          http
            .deleteKeywordRelation({ content_keyword_id: contentKeywordId })
            .then((res) => {})
            .catch(() => {
              // this.$message.error(error)
            })
        }
      }
    },
    // 删除关键词;
    async deleteKeyword(event, index) {
      let id = this.currentKeyInfo.id
      this.$confirm('确认删除？').then(() => {
        http
          .deleteKeyword({ id })
          .then((res) => {
            this.changeVisiable(index)
            this.keywordList.splice(this.currentIndex, 1)
            this.$message.success('删除成功')
            console.log('删除成功1111', res)
          })
          .catch(() => {
            this.changeVisiable(index)
          })
      })
    },
    editKeyWord() {
      let currentKeyInfo = this.currentKeyInfo
      http
        .editKeyword(currentKeyInfo)
        .then((res) => {
          let { code, msg } = res
          if (code == 200) {
            this.keywordList[this.currentIndex].name = currentKeyInfo.name
            this.$message.success('编辑成功')
          }
          console.log('编辑成功----currentKeyInfo', res)
        })
        .catch(() => {})
    },
    handleBackToList() {
      this.$router.push('/speechcraftStore')
    },
    getMaterialDetail(id) {
      http
        .getMaterialDetail(id)
        .then((res) => {
          let {
            title,
            group_id: groupId,
            type_id: typeId,
            content,
            file,
            keywords = []
          } = res.data
          this.formData.title = title
          this.formData.group_id = groupId
          this.$refs.group.value = groupId
          this.formData.type_id = typeId
          this.formData.content = content
          this.formData.fileList = file
          console.log('keywords---keywords', keywords)
          this.contentKeywordList = keywords
          this.formData.keywords =
            keywords.map((ele) => {
              return ele.id
            }) || []
        })
        .catch(() => {})
    },
    editMaterial() {
      // this.formData.type = 'speechcraft'
      // const params = this.formData
      let formDataClone = JSON.parse(JSON.stringify(this.formData))
      formDataClone.type = 'speechcraft'
      formDataClone.keywords = this.setKeyword(formDataClone.keywords)
      http
        .editMaterial(formDataClone)
        .then((res) => {
          this.$router.push('/speechcraftStore')
        })
        .catch(() => {})
    },
    // 显示编辑话术弹窗;
    showModal(index) {
      this.currentIndex = index
      this.currentKeyInfo = JSON.parse(JSON.stringify(this.keywordList[index]))
    },
    setKeyword(list = []) {
      let keywordList = this.keywordList
      let keywords = (
        list.map((ele) => {
          let currentInfo =
            keywordList.find((val) => {
              return val.id === ele
            }) || {}
          return typeof ele === 'string' ? ele : currentInfo.name || ''
        }) || []
      ).join(',')
      return keywords
    },
    addMaterial() {
      let formDataClone = JSON.parse(JSON.stringify(this.formData))
      formDataClone.type = 'speechcraft'
      formDataClone.keywords = this.setKeyword(formDataClone.keywords)
      delete formDataClone.id
      console.log(formDataClone)
      http
        .addMaterial(formDataClone)
        .then((res) => {
          this.$router.push('/speechcraftStore')
        })
        .catch(() => {})
    },
    insertText(value) {
      let position = document.getElementById('editContent')
      let pos = getPositionForTextArea(position)
      let y = position.value
      this.frontString = y.substring(0, pos)
      this.afterString = y.substring(pos, this.formData.content.length)
      this.formData.content = this.frontString + value + this.afterString

      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        // 插入表情等表情/手势,聚焦;
        this.$nextTick(() => {
          position.focus()
          position.selectionStart = position.selectionEnd = pos + value.length
        })
      }, 0)
    },
    handleSubmit() {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          if (this.type === '1') {
            this.addMaterial()
          } else {
            this.editMaterial()
          }
        }
      })
    },
    handleGroupId(id) {
      this.formData.group_id = id
    },
    handleCancel() {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="less">
.pagoda-add-layout-v1 {
  padding-left: 20px;
}
.emoji-box {
  white-space: break-spaces;
  display: flex;
  white-space: break-spaces;
  flex-wrap: wrap;
  div {
    font-size: 19px;
    width: 30px;
    text-align: center;
    margin: 10px 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.keyword-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
